import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import AutoCompleteInput from "../../../../../component/AutoCompleteInput";
import {
  API_ENDPOINT_PREFIX,
  apiRequest,
} from "../../../../../helper/fetching";
import moment from "moment";
import debounce from "lodash/debounce";

function OrderDetailSection({ mode = "" }) {
  const formik = useFormikContext();
  const isFirstRender = useRef(true);

  //Gold limit section
  const isUpperLimitPass = (limitValue = 0, factoryLimit = 0) => {
    if (!limitValue || !factoryLimit) {
      return "未有數值";
    }

    let upperLimit = limitValue * 1.04;
    if (factoryLimit >= limitValue && factoryLimit <= upperLimit) {
      return "合格";
    } else {
      return "不合格";
    }
  };

  const isLowerLimitPass = (limitValue = 0, factoryLimit = 0) => {
    if (!limitValue || !factoryLimit) {
      return "未有數值";
    }

    if (factoryLimit >= limitValue) {
      return "合格";
    } else {
      return "不合格";
    }
  };

  const goldLimitWeightRows = [
    {
      name: "gold_limit_weight_product",
      label: "限金重上限",
      valueMultipler: 1.04,
      refField: "gold_limit_weight",
      refFactoryField: "factory_gold_limit_weight",
      checkResult: isUpperLimitPass,
    },
    {
      name: "factory_gold_limit_weight_product",
      label: "限金重下限",
      valueMultipler: 1,
      refField: "gold_limit_weight",
      refFactoryField: "factory_gold_limit_weight",
      checkResult: isLowerLimitPass,
    },
  ];

  const handleOrderDateChange = (e) => {
    if (!e.target.value) {
      formik.setFieldValue("order_date", e.target.value);
      formik.setFieldValue("shipping_date", "");
      return;
    }

    formik.setFieldValue("order_date", e.target.value);
    const selectDay = new Date(e.target.value);
    const fourWeeksLater = selectDay.getTime() + 4 * 7 * 24 * 60 * 60 * 1000;
    const date = new Date(fourWeeksLater);
    while (date.getDay() !== 2) {
      date.setDate(date.getDate() + 1);
    }

    formik.setFieldValue("shipping_date", moment(date).format("YYYY-MM-DD"));
  };

  const handleCheckIdNo = async (id_no) => {
    if (!id_no) return

    try {
      const res = await apiRequest().get(
        `${API_ENDPOINT_PREFIX}/work-order/check-id-no/${id_no}`
      );

      if (res.data.exists) {
        formik.setFieldError("id_no", "該編號已存在")
      } else {
        formik.setFieldError("id_no", "")
      }
    } catch (error) {
      console.error("Error checking id_no:", error);
    }
  };

  const debouncedCheckIdNo = debounce(handleCheckIdNo, 500);

  useEffect(() => {
    if (mode === "edit" || mode === "" || mode === "add") {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      if (!formik.values.id_no) return formik.setFieldError("id_no", "必填")
      if (formik.values.id_no) {
        debouncedCheckIdNo(formik.values.id_no);
      }
    }
    return () => {
      debouncedCheckIdNo.cancel();
    };
  }, [formik.values.id_no]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} md={2} lg={2}>
          <AutoCompleteInput
            id="factory.name"
            getOptionLabel={(option) => (option ? option.name : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
            label="負責廠方"
            name="factory.name"
            value={formik.values.factory?.name}
            defaultValue={formik.values.factory}
            onChange={(factory) => formik.setFieldValue("factory", factory)}
            noOptionsText="沒有選項"
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <AutoCompleteInput
            id="client.name"
            getOptionLabel={(option) => (option ? option.name : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/client`}
            label="客人"
            name="client.name"
            value={formik.values.client?.name}
            defaultValue={formik.values.client}
            onChange={(client) => formik.setFieldValue("client", client)}
            noOptionsText="沒有選項"
            error={formik.errors?.client?.id}
            helperText={formik.errors?.client?.id}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="client_nickname"
            label="客別名"
            name="client_nickname"
            value={formik.values.client_nickname}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            name="id_no"
            label="編號"
            value={formik.values.id_no}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: true }}
            error={formik.errors.id_no}
            helperText={formik.errors.id_no}
            // disabled={mode === "edit" ? true : false}
          />
        </Grid>

        <Grid item xs={0} md={4} lg={4} />

        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            name="customer_item_no"
            label="Customer Item Number"
            value={formik.values.customer_item_no}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: true }}
            error={formik.errors.customer_item_no}
            helperText={formik.errors.customer_item_no}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="order_date"
            label="落單日期"
            name="order_date"
            value={formik.values.order_date}
            onChange={handleOrderDateChange}
            required={true}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="shipping_date"
            label="出貨日期"
            name="shipping_date"
            value={formik.values.shipping_date}
            onChange={formik.handleChange}
            required={true}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={1}>
          <FormTextInput
            variant="outlined"
            id="product_count"
            label="工單件數"
            name="product_count"
            value={formik.values.product_count}
            onChange={formik.handleChange}
            required={true}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* <Grid item xs={6} md={2} lg={1}>
          <FormTextInput
            variant="outlined"
            id="quotation_gold_weight"
            label="報價單金重"
            name="quotation_gold_weight"
            value={formik.values.quotation_gold_weight}
            onChange={formik.handleChange}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={1}>
          <FormTextInput
            variant="outlined"
            id="quotation_net"
            label="報價單NET"
            name="quotation_net"
            value={formik.values.quotation_net}
            onChange={formik.handleChange}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid> */}
      </Grid>

      <TableContainer component={Paper}>
        <Typography variant="h5" id="tableTitle" component="div">
          限金重結果表格
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>項目</TableCell>
              <TableCell>限金重</TableCell>
              <TableCell>限金重(廠)</TableCell>
              <TableCell>限金重(限值)</TableCell>
              <TableCell>結果</TableCell>
            </TableRow>
          </TableHead>
          {goldLimitWeightRows.map((goldLimitWeightRow, index) => {
            return (
              <TableRow>
                <TableCell>
                  <Typography>{goldLimitWeightRow.label}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {formik.values[goldLimitWeightRow.refField]}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {formik.values[goldLimitWeightRow.refFactoryField]}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {formik.values[goldLimitWeightRow.refField]
                      ? (
                          formik.values[goldLimitWeightRow.refField] *
                          goldLimitWeightRow.valueMultipler
                        ).toFixed(2)
                      : "未有數值"}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {goldLimitWeightRow.checkResult(
                      formik.values[goldLimitWeightRow.refField],
                      formik.values[goldLimitWeightRow.refFactoryField]
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
    </>
  );
}

export default OrderDetailSection;
